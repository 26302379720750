<template>
	<div>
	<div class="list__item" v-for="(item, index) in visibleProducts" :key="index">
    <div class="list__item--body--sm">
    	<img v-if="!item.stock || item.stock != true" src="https://firebasestorage.googleapis.com/v0/b/medean95-4d3b4.appspot.com/o/outofstock.jpg?alt=media&token=9bd98cf1-3ca5-42c3-b62d-7c65b9ba5f30" alt="" class="hiddenXsOnly" />
      <img v-if="item.stock == true" :src="item.pics[0]" alt="" class="hiddenXsOnly" />
      <div class="flex flex-wrap">
      	<div class="flex__col1">
      		<div class="hiddenSmAndUp text-center mb-4">
            <img v-if="!item.stock || item.stock != true" src="https://firebasestorage.googleapis.com/v0/b/medean95-4d3b4.appspot.com/o/outofstock.jpg?alt=media&token=9bd98cf1-3ca5-42c3-b62d-7c65b9ba5f30" alt="" />

      			<img v-if="item.stock == true" :src="item.pics[0]" alt="" />
      		</div>
	        <h3 class="mb-1">{{item.manufacturer}} {{item.part}}</h3>
	        <p><strong>{{item.category}}</strong></p>
	        <p class="mb-2">{{item.commodityDescription}}</p>
	        <button class="btn__chip">Size: {{item.size}}</button><button class="btn__chip">N95 ({{item.style}})</button><button class="btn__chip">Color: {{item.color}}</button><button class="btn__chip">Strap: {{item.strap}}</button>
      	</div>
      	<div class="flex__col2 prodDiv">
      		<div v-if="item.manufacturer != '3M'">
      			<!-- <h4>Pricing:</h4> -->
	      		<table>
	      			
              <tr>
                <td>Government Discount Price 1M+ Units:</td>
                <td>${{item.contractPrice}}/ea</td>
              </tr>
              <tr>
                <td>Government Discount Price 5M+:</td>
                <td>${{item.contractPrice5M}}/ea</td>
              </tr>
              <tr>
                <td>Government Discount Price 10M+:</td>
                <td>${{item.contractPrice10M}}/ea</td>
              </tr>
              <tr>
                <td>Non-Government List Price 1M-5M Units:</td>
                <td><span class="strike">${{item.msrp}}/ea</span></td>
              </tr>
	      		</table>
      		</div>
      		<router-link tag="button" class="btn btn__primary ml-5" :to="'/products/' + item.manufacturer + '/' + item.id">
      			See Details <i class="fad fa-chevron-right"></i>
      		</router-link>
	      </div>
      </div>
    </div>
    <!-- <div class="list__button">
      <router-link :to="`/dashboard/products/` + item.id">
        <button class="btn btn__outlined ma-2">Edit</button>
      </router-link>
    </div> -->
  </div>
  </div>
</template>

<script>
	export default {
		props: ['products'],
		computed: {
			visibleProducts() {
				return this.products.filter(product => {
					return product.visible == true
				})
			}
		}
	}
</script>