<template>
  <div class="vendor">
  	<Nav />
  	<Breadcrumbs :items="crumbs" />
    <div class="vendor__main">
    	<transition name="fade">
    		<Loader v-if="!vendorProducts" />
    	</transition>
      <div class="ml-3 mb-3 flex">
        <button class="btn btn__large btn__toggle" v-bind:class="{ btn__dark: surgical }" @click="surgical = true">Surgical</button>
        <button class="btn btn__large btn__toggle ml-3" @click="surgical = false"  v-bind:class="{ btn__dark: !surgical }">Non-Surgical</button>
      </div>
      <transition name="fadeStop">
        <ProductsList :products="surgicalProducts" v-if="surgical == true" />
      </transition>
      <transition name="fadeStop">
        <ProductsList :products="nonSurgicalProducts" v-if="surgical == false" />
      </transition>


    	<!-- <div v-if="vendorProducts">
    		<ProductsList :products="vendorProducts" />
    	</div> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Nav from '@/components/Nav.vue'
import Loader from '@/components/Loader.vue'
import ProductsList from '@/components/ProductsList.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'vendor',
  data: () => ({
    surgical: false,
  }),
  created () {
    this.$store.dispatch("getProductsFromVendor", this.$route.params.vendor);
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'vendorProducts']),
    crumbs () {
  		if (this.$route.params.vendor) {
	      let vendor = this.$route.params.vendor
	      let crumbsArray = []
	      let step1 = { title: "Catalog", to: { name: "home"}}
	      let step2 = { title: vendor, to: false}
	      crumbsArray.push(step1)
	      crumbsArray.push(step2)
	      return crumbsArray
	    } else {
        return []
      }
    },
    nonSurgicalProducts() {
      return this.vendorProducts.filter(product => {
        return (product.category != "Surgical N95 Bulk" && product.visible == true)
      })
    },
    surgicalProducts() {
      return this.vendorProducts.filter(product => {
        return (product.category == "Surgical N95 Bulk" && product.visible == true)
      })
    },
  },
  components: {
  	Nav,
  	Breadcrumbs,
  	Loader,
    ProductsList,
    Footer
  },
  beforeDestroy(){
    this.$store.dispatch('clearVendor')
  }
}
</script>